// Settings
// -------------------------
$grid-columns: 12;
$enable-negative-margins: true;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1600px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  // 4px
  2:
    (
      $spacer * 0.5,
    ),
  // 8px
  standard:
    (
      $spacer * 0.75,
    ),
  // 12px
  3: $spacer,
  // 16px
  4:
    (
      $spacer * 1.5,
    ),
  // 24px
  5:
    (
      $spacer * 2,
    ),
  // 32px
  6:
    (
      $spacer * 2.5,
    ),
  // 40px
  7:
    (
      $spacer * 3,
    ),
  // 48px
  8:
    (
      $spacer * 4,
    ),
  // 54px
  9:
    (
      $spacer * 4.5,
    ),
  // 72px
  10:
    (
      $spacer * 5,
    ),
  // 80px
  11:
    (
      $spacer * 7.5,
    ),
  // 120px
  12:
    (
      $spacer * 10,
    ),
  // 160px
  13:
    (
      $spacer * 12.5,
    ),
  // 200px
);

// Brand colors
// -------------------------

$color-white: #fff;
$color-primary: #024582;
$color-poligenius-blue-akzent: #00b1eb;
$color-poligenius-blue-footer: #4085c6;
$color-poligenius-akzent: #e9e229;
$color-poligenius-akzent-light: #faf8c9;
$color-poligenius-blue-background-dark: #bedcf8;
$color-poligenius-blue-background-dark-transparent: rgba(190, 220, 248, 0.8);
$color-poligenius-blue-background-medium: #dbf3ff;
$color-poligenius-blue-background-light: #f1faff;
$color-poligenius-red: #fd9286;
$color-poligenius-red-light: #ffe4e1;
$color-poligenius-green: #fd9286;
$color-poligenius-green-light: #ffe4e1;
$color-poligenius-elementary-school: rgba(211, 235, 199, 0.3);
$color-poligenius-secondary-school: rgba(230, 200, 235, 0.3);
$color-poligenius-high-school: rgba(253, 187, 181, 0.3);

$color-default: #fff;
$color-secondary: #fff;

$color-black: #121212;
$color-grey: #707070;
$color-grey-light: #EAEAEA;

$interrupter-color: $color-primary;

// bootstrap variables override
$primary: $color-primary;
$secondary: $color-secondary;

// text

$headings-margin-bottom: $spacer;

// theme colors
$theme-colors: (
  "primary": $primary,
  "default": $color-white,
  "poligenius-elementary-school": $color-poligenius-elementary-school,
  "poligenius-secondary-school": $color-poligenius-secondary-school,
  "poligenius-high-school": $color-poligenius-high-school,
  "poligenius-blue-akzent": $color-poligenius-blue-akzent,
  "poligenius-akzent": $color-poligenius-akzent,
  "poligenius-blue-footer": $color-poligenius-blue-footer,
  "poligenius-akzent-light": $color-poligenius-akzent-light,
  "poligenius-blue-background-dark": $color-poligenius-blue-background-dark,
  "poligenius-blue-background-dark-transparent":
    $color-poligenius-blue-background-dark-transparent,
  "poligenius-blue-background-medium": $color-poligenius-blue-background-medium,
  "poligenius-blue-background-light": $color-poligenius-blue-background-light,
  "poligenius-red": $color-poligenius-red,
  "poligenius-red-light": $color-poligenius-red-light,
  "poligenius-green": $color-poligenius-green,
  "poligenius-green-light": $color-poligenius-green-light,
  "secondary": $color-secondary,
  "body": $color-black,
  "white": $color-white,
  "gray": $color-grey,
  "gray-light": $color-grey-light,
  "none": transparent,
) !default;

$utilities: (
  "heading": (
    property: font-size,
    class: heading,
    responsive: true,
    values: (
      xxxl: 5rem,
      xxl: 4rem,
      xl: 3.5rem,
      lg: 3rem,
      md: 2.5rem,
      sm: 2rem,
      xs: 1.5rem,
      xxs: 1.25rem,
      3xs: 1.125rem,
      4xs: 1rem,
      5xs: 0.875rem,
    ),
  ),
  "body": (
    property: font-size,
    class: body,
    responsive: true,
    values: (
      3xl: 2rem,
      xxl: 1.5rem,
      xl: 1.25rem,
      lg: 1.125rem,
      md: 1rem,
      sm: 0.875rem,
      xs: 0.75rem,
    ),
  ),
  "text": (
    property: color,
    class: text,
    state: hover,
    responsive: true,
    values: $theme-colors,
  ),
  "bg": (
    property: background-color,
    class: bg,
    state: hover,
    responsive: true,
    values: $theme-colors,
  ),
  "hyphens": (
    property: hyphens,
    class: hyphens,
    responsive: true,
    values: (
      none: none,
      manual: manual,
      auto: auto,
    ),
  ),
  "position": (
    property: position,
    responsive: true,
    values: static relative absolute fixed sticky initial inherit,
  ),
  "height": (
    property: height,
    class: h,
    responsive: true,
    values: (
      content: fit-content,
      max-content: max-content,
      11px: 11px,
      16px: 16px,
      24px: 24px,
      32px: 32px,
      40px: 40px,
      48px: 48px,
      75px: 75px,
      96px: 96px,
      156px: 156px,
      248px: 248px,
      550px: 500px,
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto,
      500px: 500px,
    ),
  ),
  "width": (
    property: width,
    class: w,
    responsive: true,
    values: (
      max-content: max-content,
      13px: 13px,
      16px: 16px,
      24px: 24px,
      32px: 32px,
      40px: 40px,
      48px: 48px,
      80px: 80px,
      96px: 96px,
      156px: 156px,
      248px: 248px,
      25: 25%,
      50: 50%,
      85: 85%,
      100: 100%,
      auto: auto,
    ),
  ),
  "max-width": (
    property: max-width,
    class: mw,
    responsive: true,
    values: (
      max: 729px,
      full: 100%,
    ),
  ),
  "max-height": (
    property: max-height,
    class: mh,
    responsive: true,
    values: (
      max-content: max-content,
      max: 384px,
    ),
  ),
  "min-height": (
    property: min-height,
    class: minh,
    responsive: true,
    values: (
      16px: 16px,
      24px: 24px,
      56px: 56px,
      338px: 338px,
      660px: 660px,
      100: 100%,
    ),
  ),
  "min-width": (
    property: min-width,
    class: minw,
    responsive: true,
    values: (
      16px: 16px,
      24px: 24px,
    ),
  ),
  "border-radius": (
    property: border-radius,
    class: rounded,
    responsive: true,
    values: (
      2: 0.5rem,
      3: 1rem,
    ),
  ),
  "z-index": (
    property: z-index,
    class: z,
    responsive: true,
    values: (
      1: 1,
      2: 2,
      3: 3,
      -1: -1,
    ),
  ),
  "object-fit": (
    property: object-fit,
    class: object-fit,
    responsive: true,
    values: (
      cover: cover,
      contain: contain,
      fill: fill,
    ),
  ),
  "gap": (
    property: gap,
    class: gap,
    responsive: true,
    values: (
      8px: 8px,
      4: $spacer * 1.5,
    ),
  ),
);
